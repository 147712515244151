<template>
  <ul class="search-bar-wrap"
      :class="[isIndex ? ['pc:shadow-md', 'mx-auto'] : ['pc:border'],{'border-osn-grey-03':isResult }].flat()">
    <li :class="isIndex&&isMobile?'':''">
      <svg-icon name="city"
                size="20"></svg-icon>
      <el-select
          v-model="selectedCity"
          @change="selectCityOption"
          placement="bottom"
          class="type-dropdown"
          clearable
          :placeholder="$t('menu.location')"
      >
        <el-option
            v-for="item in cityList"
            :key="item.city_id"
            :label="`${item.city_name}`"
            :value="item.city_id"
        />
      </el-select>
    </li>
    <li :class="isIndex&&isMobile?'':''">
      <svg-icon name="category"
                size="20"></svg-icon>
<!--      <el-select
          v-model="selectedSpace.type"
          @change="selectTypeOption"
          class="type-dropdown"
          placement="bottom"
          clearable
          :placeholder="$t('menu.type')"
      >
        <el-option
            v-for="item in spaceTypeList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
        />
      </el-select>-->
      <el-select
          v-model="selectedSpace.usage"
          @change="selectUsageOption"
          class="type-dropdown"
          placement="bottom"
          clearable
          :placeholder="$t('menu.usage')"
      >
        <el-option
            v-for="item in spaceUsageList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
        />
      </el-select>
    </li>
    <li :class="isIndex?['pc:min-w-[160px]','mobile:shadow-md']:['pc:min-w-[120px]']"
        ref="datePickerRef"
        @click="showDatePickerPopover">
      <svg-icon name="calendar"
                size="20"></svg-icon>
      <span :class="isIndex?['mobile:text-left']:['leading-none']">{{ formattedPickerDateTime }}</span>
    </li>
    <li v-if="isMobile&&!isIndex" class="mb-4">
      <DropdownSelectFilter :filter-name="peopleFilterConfig.filterName"
                            :option-list="peopleFilterConfig.optionList"
                            :range-config="peopleFilterConfig.rangeConfig"
                            :initial-range="peopleFilterConfig.initialRange"
                            @update:selected-item="handlePeopleChange"></DropdownSelectFilter>
    </li>
    <li v-if="isMobile&&!isIndex" >
      <DropdownSelectFilter :filter-name="priceFilterConfig.filterName"
                            :option-list="priceFilterConfig.optionList"
                            :range-config="priceFilterConfig.rangeConfig"
                            :initial-range="priceFilterConfig.initialRange"
                            @update:selected-item="handlePriceChange"></DropdownSelectFilter>
    </li>
    <li v-if="isMobile"
        class="pr-3">
      <SearchFilter v-if="!isIndex"></SearchFilter>
    </li>
    <li @click="onSearch"
        class="text-center bg-osn-yellow-00 text-[15px] font-semibold leading-[40px] pc:rounded-r-full mobile:rounded-full"
        :class="isIndex?['mobile:w-full','pc:min-w-[100px]']:['mobile:min-w-[100%]','pc:min-w-[70px]']">
      {{ $t('menu.search') }}
    </li>
  </ul>


  <el-popover
      ref="datePickerPopoverRef"
      :virtual-ref="datePickerRef"
      :visible="isShowPopover"
      trigger="click"
      virtual-triggering
      width="310px"
      :show-arrow="false"
      placement="bottom-start"
  >
    <div class="date-picker-popover">
      <div class="mb-2 flex justify-between">
        <div class="title">{{ $t('menu.use_time') }}</div>
        <el-icon :size="20" class="cursor-pointer" @click="hideDatePickerPopover"><Close /></el-icon>
      </div>
      <el-date-picker
          v-model="searchDate"
          @change="setRentTime"
          class="date-picker"
          type="date"
          value-format="YYYY/MM/DD"
          :disabled-date="disabledDate"
          :placeholder="$t('menu.choose_date')"
          :editable="false">
      </el-date-picker>
      <div class="day-select"
           @click="isAllDay=!isAllDay,setRentTime()">
        <svg-icon v-if="isAllDay"
                  class="mr-[6px] bg-osn-yellow-00 cursor-pointer"
                  name="check_box"
                  size="18"></svg-icon>
        <div v-else
             class="check-box"></div>
        <span>{{ $t('menu.all_day') }}</span>
      </div>
      <div class="flex items-end time-select">
        <div>
          <div>{{ $t('menu.start_time') }}</div>
          <el-time-select
              v-model="searchStartTime"
              @change="setRentTime"
              :max-time="searchEndTime"
              class="rounded-full"
              :placeholder="$t('menu.time')"
              start="09:00"
              step="00:30"
              end="21:00"
              :editable="false"
              :disabled="isAllDay||!searchDate"/>
        </div>

        <div class="mb-[10px] mx-[6px]">
          <el-icon>
            <Right/>
          </el-icon>
        </div>
        <div>
          <div>{{ $t('menu.end_time') }}</div>
          <el-time-select
              v-model="searchEndTime"
              @change="handleSetRentTime"
              :min-time="searchStartTime"
              :placeholder="$t('menu.time')"
              start="09:00"
              step="00:30"
              end="21:00"
              :editable="false"
              :disabled="isAllDay||!searchDate"
          />
        </div>

      </div>
      <div class="mt-10 flex justify-between">
        <div></div>
        <div class="w-[90px] leading-[40px] text-center bg-osn-yellow-00 text-[15px] font-semibold rounded-full cursor-pointer"
             @click="onSearch">搜尋</div>
      </div>



    </div>
  </el-popover>

  <!--  日期 el-popover 的背景-->
  <div class="absolute top-0 bottom-0 right-0 left-0"
       v-if="isShowPopover"
       @click="hideDatePickerPopover"></div>
</template>
<script setup>
import {computed, defineProps, onMounted, ref, watch, watchEffect} from "vue";
import {useCityStore} from "@/stores/city.js";
import {useCategoryStore} from "@/stores/category.js";
import {useSearchQueryStore} from "@/stores/searchQuery.js";
import {Right,Close} from "@element-plus/icons-vue";
import {useI18n} from '#imports';

import {useGoTo} from "@/composables/useGoTo";
import {useSearchWithStore} from "@/composables/useSearchWithStore";
import {useSettingStore} from "@/stores/setting.js";
import SearchFilter from "@/components/SearchResult/SearchFilter.vue";
import {useFilterStore} from "@/stores/filter.js";

import DropdownSelectFilter from "~/components/SearchResult/DropdownSelectFilter.vue";
import {priceList, priceRange, peopleList, peopleCapRange} from "@/config/common.js";


const props = defineProps({
  isIndex: {
    type: Boolean,
    default: false
  },
  isResult: {
    type: Boolean,
    default: false
  }
})
const {$fbq} = useNuxtApp()

const categoryIds = {
  // 類型 VITE_APP_BASE_TYPE_CATEGORY_ID="2"
  // 用途 VITE_APP_BASE_USAGE_CATEGORY_ID="3"
  type: import.meta.env.VITE_APP_BASE_TYPE_CATEGORY_ID.toString(),
  usage: import.meta.env.VITE_APP_BASE_USAGE_CATEGORY_ID.toString(),
};

const selectedSpace = reactive({
  type:-1,
  usage:-1
})

const settingStore = useSettingStore()
const cityStore = useCityStore();
const categoryStore = useCategoryStore()
const searchQueryStore = useSearchQueryStore()
const filterStore = useFilterStore();
const {handleSearchWithStore} = useSearchWithStore()
const {goWithQuery} = useGoTo();
const {t} = useI18n();

const selectedCity = ref('');
const searchDate = ref('')
const isAllDay = ref(false)
const searchStartTime = ref('')
const searchEndTime = ref('')
const disabledDate = (time) => {
  const now = new Date();
  const todayStart = new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime();
  const thirtyDaysLater = todayStart + 29 * 24 * 60 * 60 * 1000; // 配合預訂的限制
  return time.getTime() < todayStart || time.getTime() > thirtyDaysLater;
}

const datePickerRef = ref()
const datePickerPopoverRef = ref()
const isShowPopover = ref(false)

const isMobile = computed(() => settingStore.isMobile);
const cityList = computed(() => cityStore.getCityList)
const spaceTypeList = computed(() => categoryStore.getSpaceTypeCategories)
const spaceUsageList = computed(() => categoryStore.getUsageCategories)

const formattedPickerDateTime = computed(() => {
  const date = searchDate.value;
  let dateTimeDisplay = t('menu.time');

  if (date) {
    dateTimeDisplay = date;
  }

  if (searchStartTime.value && searchEndTime.value) {
    const timeRange = `${searchStartTime.value}~${searchEndTime.value}`;
    dateTimeDisplay = `${dateTimeDisplay.slice(-5)} ${timeRange}`;
  }

  return dateTimeDisplay
});

watchEffect(() => {
  const cityId = searchQueryStore.getSearchCityId;
  if (cityId && cityId[0]) {
    selectedCity.value = cityId[0];
  } else {
    selectedCity.value = null;
  }
});

/*watch(() => searchQueryStore.getSearchCategoryIds, (newCategoryObject) => {
  // 類型
  if (newCategoryObject && newCategoryObject[typeCategoryId]) {
    selectedSpaceType.value = newCategoryObject[typeCategoryId][0];
  } else {
    selectedSpaceType.value = '';
  }

  // 用途
  if (newCategoryObject && newCategoryObject[usageCategoryId]) {
    selectedSpaceUsage.value = newCategoryObject[usageCategoryId][0];
  } else {
    selectedSpaceUsage.value = '';
  }
}, {
  deep: true,
  immediate: true
});*/
// 通用監聽函式
function watchSelectedSpace(categoryKey) {
  const categoryId = categoryIds[categoryKey];
  watch(
      () => searchQueryStore.getSearchCategoryIds,
      (newCategoryObject) => {
        if (newCategoryObject && newCategoryObject[categoryId]) {
          selectedSpace[categoryKey] = newCategoryObject[categoryId][0];
        } else {
          selectedSpace[categoryKey] = '';
        }
      },
      {
        deep: true,
        immediate: true,
      }
  );
}

// 初始化監聽
watchSelectedSpace('type');
watchSelectedSpace('usage');


watch(() => searchQueryStore.getRentTimeSelected, (newRentTime) => {

  if (newRentTime) {
    const {rent_type, begin_time, end_time} = newRentTime;
    searchDate.value = begin_time ? begin_time.split(' ')[0] : '';
    isAllDay.value = rent_type === 1;
    searchStartTime.value = begin_time ? begin_time.split(' ')[1].slice(0, 5) : '';
    searchEndTime.value = end_time ? end_time.split(' ')[1].slice(0, 5) : '';
  }
}, {
  deep: true,
  immediate: true
});

onMounted(() => {

  if (props.isIndex) {
    searchQueryStore.initQueryDate()
  }
})


function selectCityOption() {
  if (!selectedCity.value) {
    searchQueryStore.clearCitySelected()
    return
  }
  searchQueryStore.setSelectedCityID([selectedCity.value])
}

/*function selectedSpaceTypeOption() {
  if (!selectedSpaceType.value) {
    searchQueryStore.clearCategoryObjectByKey(typeCategoryId)
    return
  }
  searchQueryStore.setSelectedCategoryObject({
    [typeCategoryId]: [selectedSpaceType.value]
  });
}

function selectedSpaceUsageOption() {
  if (!selectedSpaceUsage.value) {
    searchQueryStore.clearCategoryObjectByKey(usageCategoryId)
    return
  }
  searchQueryStore.setSelectedCategoryObject({
    [usageCategoryId]: [selectedSpaceUsage.value]
  });
}*/

// 通用選擇 category
function selectSpaceOption(categoryKey) {
  const categoryId = categoryIds[categoryKey];

  if (!selectedSpace[categoryKey]) {
    searchQueryStore.clearCategoryObjectByKey(categoryId);
    return;
  }


  searchQueryStore.setSelectedCategoryObject({
    [categoryId]: [selectedSpace[categoryKey]]
  });
}


function selectTypeOption() {
  selectSpaceOption('type');
}

function selectUsageOption() {
  selectSpaceOption('usage');
}


function setRentTime() {
  const date = searchDate.value;
  const beginTime = isAllDay.value || !searchStartTime.value ? '00:00:00' : searchStartTime.value + ':00'
  const endTime = isAllDay.value || !searchEndTime.value ? '23:59:59' : searchEndTime.value + ':00'
  searchQueryStore.setRentTimeSelected({
    rent_type: isAllDay.value ? 1 : 0,
    begin_time: date ? `${date} ${beginTime}` : '',
    end_time: date ? `${date} ${endTime}` : ''
  })


}

function showDatePickerPopover() {
  isShowPopover.value = true
}

function hideDatePickerPopover() {
  isShowPopover.value = false
}

function handleSetRentTime() {
  setRentTime()
  hideDatePickerPopover()
}

const priceFilterConfig = {
  filterName: t('menu.price'),
  optionList: priceList,
  rangeConfig: priceRange,
  initialRange: searchQueryStore.getPriceRange,
};
const handlePriceChange = (newValue) => {
  searchQueryStore.setSelectedPriceRange(priceFilterConfig.rangeConfig[newValue] || {});
};

const peopleFilterConfig = {
  filterName: t('menu.people'),
  optionList: peopleList,
  rangeConfig: peopleCapRange,
  initialRange: searchQueryStore.getPeopleCapRange,
};
const handlePeopleChange = (newValue) => {
  searchQueryStore.setSelectedPeopleCapRange(peopleFilterConfig.rangeConfig[newValue] || {});
};

function onSearch() {
  const tagCategoryId = import.meta.env.VITE_APP_BASE_TAG_CATEGORY_ID
  searchQueryStore.initGeoSearch()
  searchQueryStore.clearKeyword()
  // filter 塞到 searchQueryStore
  const categoryTagIDList = filterStore.getSelectedCategoryTag
  const selectedEquipmentList = filterStore.getSelectedEquipment
  const selectedServiceList = filterStore.getSelectedService

  searchQueryStore.setEquipmentIds(selectedEquipmentList)
  searchQueryStore.setServiceIds(selectedServiceList)

  searchQueryStore.setSelectedCategoryObject({
    [tagCategoryId]: categoryTagIDList
  });
  searchQueryStore.setPagination({page: 1})
  $fbq('trackCustom', 'OSSearch')
  handleSearchWithStore()
  goWithQuery('/search-result', {})

}

</script>
<style scoped
       lang="scss">

/*pc版本*/
@media (min-width: 768px) {
  ul.search-bar-wrap {
    @apply h-[40px] leading-[calc(54px-(9px*2))] inline-flex text-[14px] bg-osn-white-00 rounded-full;

    li:not(:first-child) {
      @apply border-l;
    }

    li:not(:first-child,:nth-child(2)) {
      @apply cursor-pointer w-[30%];
    }

    li:not(:last-child) {
      @apply my-[9px] text-osn-grey-02 font-normal inline-flex items-center;

      svg {
        @apply min-w-[20px] mx-[5px];
      }

      select {
        @apply w-full h-full outline-none cursor-pointer;
      }

      .type-dropdown {
        @apply cursor-pointer;
        --el-select-input-focus-border-color: '#FFFFFF';

        :deep(.el-select__wrapper) {
          @apply w-[130px] h-[38px];
          background-color: transparent;
          border-radius: 0;
          box-shadow: 0 0 0 0;
        }
      }
    }

  }
}

/*mobile*/
@media (max-width: 767px) {
  ul.search-bar-wrap {
    @apply px-4 flex flex-wrap justify-between text-[14px];

    li:not(:nth-child(4),:nth-child(5),:nth-child(6),:last-child) {
      @apply mx-[4px] mb-[16px] min-w-[31%] max-w-[32%] flex-1 text-osn-grey-02 font-normal
      inline-flex items-center bg-osn-white-00 rounded-full border border-osn-grey-03;

      svg {
        @apply min-w-[20px] ml-[12px] mr-[6px];
      }

      select {
        @apply outline-none cursor-pointer;
      }

      .type-dropdown {
        @apply mr-2;
        --el-select-input-focus-border-color: '#FFFFFF';

        :deep(.el-select__selection ) {
          @apply w-fit;
        }

        :deep(.el-select__wrapper) {
          @apply justify-normal p-0 w-full h-[38px];
          background-color: transparent;
          border-radius: 0;
          box-shadow: 0 0 0 0;

          .el-select__selected-item {
            @apply max-w-fit truncate;
          }
        }
      }

      span {
        @apply leading-none;
      }
    }
  }
}

.date-picker-popover {
  @apply text-[15px] text-osn-black-00;

  :deep(.date-picker) {
    width: 282px !important;
    height: 44px !important;
  }

  :deep(.el-input__wrapper) {
    @apply rounded-full;
  }

  .day-select {
    @apply mt-[12px] mb-[37px] min-w-[110px] cursor-pointer flex items-center;
    .check-box, img {
      @apply mr-[6px] w-[18px] h-[18px] cursor-pointer;
    }

    .check-box {
      @apply border-2 border-osn-dark-grey-00;
    }
  }
}

.time-select {
  :deep(.el-select) {
    /*開始時間 結束時間*/
    .el-select__wrapper {
      @apply w-[130px] h-[38px] rounded-full;
    }
  }
}

</style>

